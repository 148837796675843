<template>
  <div v-if="userData">
    <b-row>
      <b-col cols="12" xl="9" lg="8" md="7">
        <user-view-user-info-card :user-data="userData" />
      </b-col>
      <b-col cols="12" md="5" xl="3" lg="4">
        <user-view-user-plan-card
          v-if="userData.role_name === 'customer'"
          :user-data="userData"
        />
        <user-view-establishment-card
          v-if="userData.role_name === 'store_clerk'"
          :user-data="userData"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6">
        <user-view-user-permissions-card />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from "@/store"
import router from "@/router"
import { ref, onUnmounted } from "@vue/composition-api"

import userStoreModule from "../userStoreModule"
import UserViewUserInfoCard from "./UserViewUserInfoCard.vue"
import UserViewUserPlanCard from "./UserViewUserPlanCard.vue"
import UserViewUserPermissionsCard from "./UserViewUserPermissionsCard.vue"
import UserViewEstablishmentCard from "./UserViewEstablishmentCard.vue"

export default {
  components: {
    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserPermissionsCard,
    UserViewEstablishmentCard,
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = "app-user"

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store
      .dispatch("app-user/fetchUser", { id: router.currentRoute.params.id })
      .then((response) => {
        userData.value = response.data
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
    }
  },
}
</script>

<style></style>
